@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("./assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"), url("./assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"), url("./assets/fonts/Montserrat-Bold.ttf");
}
