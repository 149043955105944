@import "./fonts.scss";

body {
  background-color: #e5e5e5;
}

* {
  font-family: Montserrat;
  color: #414d67;
}
